<template>
    <div>
      <b-modal
        @hidden="resetModal"
        ref="modal-import-payout"
        centered
        hide-footer
        size="lg"
        :no-close-on-backdrop="true"
        content-class="overflow-hidden"
      >
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark"><i class="uil uil-receipt-alt text-primary"></i> Import Payout</h5>
      </template>
        <div class="row align-items-center">
          <div class="col-12">
            <h5 class="font-size-14 fw-normal text-muted mb-3"> Import Details</h5>
          </div>
          <div class="col-12" >
            <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Merchant</label>
            <div class="col-lg-9 col-12">
              <multiselect v-model="selectedmerchant" :options="merchant" label="account_name" class="" placeholder="Select Merchant" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="disabled" @input="changeMerchant()" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.merchantId.$error,
                  }">
              <template slot="singleLabel" slot-scope="props">
                <span v-if="props.option.account_db_code!=='all'">
                  <span class="option__title">
                  <span v-if="props.option.status=='inactive'" class="text-danger me-1 text-uppercase">&#9679;</span>
                  <span v-else-if="props.option.status=='active'" class="text-success me-1 text-uppercase">&#9679; </span>
                  #{{ props.option.id }} {{ props.option.account_name }}</span>
                </span>
                <span v-else>
                  {{ props.option.account_name }} <span v-if="merchant.length>1">({{ merchant.length-1 }})</span><span v-else>(0)</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <span v-if="props.option.account_db_code!=='all'">
                  <span class="option__title">
                  <span v-if="props.option.status=='inactive'" class="text-danger me-1 text-uppercase">&#9679;</span>
                  <span v-else-if="props.option.status=='active'" class="text-success me-1 text-uppercase">&#9679; </span>
                  #{{ props.option.id }} {{ props.option.account_name }}</span>
                </span>
                <span v-else>
                  {{ props.option.account_name }} <span v-if="merchant.length>1">({{ merchant.length-1 }})</span><span v-else>(0)</span>
                </span>
              </template>
              <span slot="noResult">Oops! No merchant found. </span>
            </multiselect>
              <div v-if="modalSubmit && $v.formData.merchantId.$error" class="invalid-feedback">
                <span v-if="!$v.formData.merchantId.required">Merchant is required.</span>
                <span v-else-if="!$v.formData.merchantId.equalDefault">Merchant Id is required.</span>
              </div>
            </div>
            </div>
            <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Available Balance</label>
            <div class="col-lg-9 col-12">
              <multiselect
                  ref="select"
                  v-model="selectedmerchantContract"
                  :options="merchantContract"
                  label="account_name"
                  class="mb-3"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="changeContract()"
                  :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.contractId.$error,
                  }"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id!=='-1'">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.contract_id }}: {{ props.option.contract_name }} - {{ props.option.contract_currency }} 
                      <span v-if="props.option.contract_balance">
                        {{ convertCurrencyFormat(props.option.contract_balance, true)}}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id!=='-1'">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title">  {{ props.option.contract_id }}: {{ props.option.contract_name }} - {{ props.option.contract_currency }} 
                      <span v-if="props.option.contract_balance">
                        {{ convertCurrencyFormat(props.option.contract_balance, true)}}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
              </multiselect>
              <div v-if="modalSubmit && $v.formData.contractId.$error" class="invalid-feedback">
                <span v-if="!$v.formData.contractId.required">Contract is required.</span>
                <span v-else-if="!$v.formData.contractId.equalDefault">Contract Id is required.</span>
              </div>
              <div class="mt-2" v-if="selectedmerchantContract.contract_id !==-1">
                <h5 class="font-size-14 bg-soft-primary text-primary p-2 fw-medium mb-0 d-inline-block rounded">
                  <i class="uil uil-wallet"></i>
                  {{selectedmerchantContract.contract_currency}}
                  <span v-if="selectedmerchantContract.contract_balance">
                    {{ convertCurrencyFormat(selectedmerchantContract.contract_balance, true)}}
                  </span>
                  <span v-else>0.00</span>
                </h5>
              </div>
            </div>
          </div>
            
          <div class="mb-3 row " v-if="selectedmerchantContract.contract_id !==-1">
            <label class="col-lg-3 col-12 col-form-label">Upload File (xls,xlsx)
              <div><a href="https://file-examples.com/wp-content/storage/2017/02/file_example_XLS_10.xls" class="fw-normal" target="_blank"><u>Download Format</u></a></div>
            </label>
            <div class="col-lg-9 col-12">
              <input class="form-control" :disabled="!parseFloat(selectedmerchantContract.contract_balance)>0" :class="{ 'border border-danger animate__animated animate__shakeX is-invalid': modalSubmit && $v.formData.files.$error}"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" id="formFile" @change="onChangeFile">

            <div v-if="modalSubmit && $v.formData.files.$error" class="invalid-feedback">
              <span v-if="!$v.formData.files.required">Please upload file.</span>
            </div>
            </div>
          </div>
          
          <hr>
           
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-import-payout'].hide()">Cancel</button>
            
            <button type="button" class="btn fw-medium btn-info" @click="addPayoutRequest" :disabled="modalLoading || !parseFloat(selectedmerchantContract.contract_balance)>0">
              <span v-if="modalLoading"> Submitting...</span>
              <span v-else> Submit </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
          
        </div>
      </b-modal>
      <Common ref="commonFunc" />
    </div>
  </template>
  <script>

  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { required} from "vuelidate/lib/validators";
  //const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  // const validWebsiteUrl = (value) => {
  //   console.log(value);
  //   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
  //   return regex.test(value);
  // }
  // const validUsername = (value) => {
  //   const regex = /^[a-z0-9]+$/;
  //   return regex.test(value);
  // };

 const equalDefault = (value) => {
  return value !== -1;
  }
  
  /**
   * Starter page
   */
  const BankDefault = []
  const MerchantDefault = {
    "id": "",
    "account_db_code": "all",
    "account_name": "All Merchants",
    "status": "active",
    "reseller_name": "",
    "reseller_id": "",
    "merchant_code": ""
  }       
  const MerchantContractDefault = {
    "status": "0",
    "payin_rate": 0,
    "contract_id": "-1",
    "payout_rate": 0,
    "total_payin": 0,
    "ewallet_rate": 0,
    "total_payout": 0,
    "contract_name": "Select Contract",
    "contract_total": 0,
    "contract_status": "",
    "settlement_rate": 0,
    "contract_balance": 0,
    "total_settlement": 0,
    "contract_currency": "",
    "payin_extra_charges": 0,
    "payout_extra_charges": 0,
    "ewallet_extra_charges": 0,
    "settlement_extra_charges": 0,
    "contract_list_length_total": 0,
    "merchant_Id": "",
    "account_name": "",
    "merchant_status": "",
    "reseller_id": "",
    "reseller_name": "",
    "reseller_code": "",
    "reseller_level": 0
}
  
  export default {
    components: { 
      Multiselect,
      Common
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
      return {
        accessToken:'',
        accessEmail:'',
        accessPhone:'',
        accessUsername:'',
        selectedmerchant: MerchantDefault,
        merchant: [MerchantDefault],
        selectedmerchantContract: MerchantContractDefault,
        merchantContract: [MerchantContractDefault],
        selectedBanks:[],
        banksList:[],
        loading:false,
        disabled:false,
        modalSubmit:false,
        modalLoading:false,
        returnData: [],
        formData: {
          merchantId:"",
          contractId:"",
          files: "",
        }
      };
    },
    validations: {
      formData: {
        merchantId:{
          required,
        },
        contractId:{
          required,
          equalDefault
        },
        files:{
          required
        },
      },
    },
    middleware: "authentication",
    async mounted(){
     
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
      
    }, 
    created(){
     
    },
    methods:{
      inputNumberOnly(event, parentModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this.formData[parentModel] = numericValue;
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      showModal(){
        this.$refs['modal-import-payout'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.getMerchant();
      },
      changeContract(){
        this.selectedBanks=BankDefault;
        this.formData.contractId = this.selectedmerchantContract.contract_id;
        //this.getBankList();
      },
      changeMerchant() {
        this.formData.merchantId = this.selectedmerchant.account_db_code;
        this.getMerchantContract();
      },
      getBankList(){
        console.log("getBankList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("currency",  this.selectedmerchantContract.currencyName);
        var posturl = ""
        if (this.selectedmerchantContract.currencyName=='MYR'){
          posturl="/api/bank_list_my.json"
        }
        else if (this.selectedmerchantContract.currencyName=='IDR'){
          posturl="/api/bank_list_id.json"
        }
        else if (this.selectedmerchantContract.currencyName=='VND'){
          posturl="/api/bank_list_vn.json"
        }
        else if (this.selectedmerchantContract.currencyName=='INR'){
          posturl="/api/bank_list_in.json"
        }
        else{
          return
        }
        axios({
            method: "get",
            url: posturl,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            console.log(resData.result)
            if (resData.status == 200) {
              this.selectedBanks = BankDefault
                 
              this.banksList = [this.selectedBanks]
             
              const popular = []
              const other = []
              resData.result.forEach(element => {
                if (element.category === 'popular') {
                  popular.push(element);
                } else {
                  other.push(element);
                }
              });
              this.banksList = [{
                category: 'Popular',
                list: popular
              },
              {
                category: 'Other',
                list: other
              }]
              this.selectedBanks = this.banksList[0].list[0];
              this.formData.bankCode = this.selectedBanks.bankCode;
             
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      getMerchant() {
        console.log("getMerchant")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.merchant = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "999999");
        bodyFormData.append("resellerId", 'all');
        bodyFormData.append("searchMerchant", '');
        
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getAllMerchantList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.merchant = [];
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total;
              var responseData = resData.data;

              responseData.forEach(element => {
                  this.merchant.push(element)
              });
              if (this.merchant.length > 0) {
                this.selectedmerchant = this.merchant[0]
                this.changeMerchant()
              }
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              this.loading = false;
              this.disabled = false
            }
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
      },
      getMerchantContract() {
        console.log("getMerchantContract");
        this.$Progress.start();
        this.loading = true;
        this.disabled = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999999");
        bodyFormData.append("reseller",  "all" );
        bodyFormData.append("merchant", this.selectedmerchant.account_db_code);
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getContractListForMerchant',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              this.selectedmerchantContract = []
              this.merchantContract = []
                resData.data.forEach((element) => {
                  this.merchantContract.push(element);
                });
                if (this.merchantContract.length > 0) {
                  this.selectedmerchantContract = this.merchantContract[0]
                  this.currencyMax = parseFloat(this.selectedmerchantContract.contract_balance)
                }
                this.changeContract()
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      }, 
      resetModal() {
        this.selectedmerchantContract = MerchantDefault
        this.selectedBanks=BankDefault
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          contractId:"",
          files:"",
        }
      },
      async onChangeFile(e) {
        let file = e.target.files[0];
        this.formData.files = file
      },
      addPayoutRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("username",  this.accessUsername);
          // bodyFormData.append("upiID", this.addUPI.upi_id);
          // bodyFormData.append("upiName", this.addUPI.upi_name);
          // bodyFormData.append("type", this.addUPI.upi_type);
          // bodyFormData.append("transactionLimit", this.addUPI.transaction_limit.replaceAll(",",""));
  
          axios({
              method: "post",
              url: appConfig.DemoAPI,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Payout Request Created',
                  html: `Your payout request has been successfully created!`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-import-payout'].hide()
                  console.log(this.$route.name)
                  if (this.$route.name =='pending payouts'){
                    this.$emit('callParentFunction');
                  }else{
                    this.$router.push({
                      name: "pending payouts",
                   });
                  }
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
  
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.formData.apiID = data.apiID;
            this.formData.apiHash = data.apiHash;
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
  
    }
  };
  </script>
<style scoped>
.min-height{
  min-height: 200px;
}
</style>